import { TextField, Button, theme } from "@project/shared";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ClubFormInterface } from "../../../src/interfaces/Club";
import { useQuery } from "react-query";
import { useRouter } from "next/router";
import { getClub } from "../../../services/club";

const Container = styled.div`
  border-radius: 2px;
  margin: 24px;
  background-color: ${theme.white};
`;
const FormContainer = styled.div`
  padding: 24px;
`;
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 10px;
`;

interface ClubProps {
  handleSubmit: any;
  done?: boolean;
  loading?: boolean;
}

const ClubForm = ({ handleSubmit, done, loading }: ClubProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { id } = router.query;

  const { data: value } = useQuery(["clubs", id], () => getClub(id), {
    refetchOnWindowFocus: false,
  });

  const validationSchema = yup.object().shape({
    club_name: yup.string().required(t("Club Name is required.")),
    incharge: yup.string().required(t("In Charge is required.")),
    email: yup
      .string()
      .email(t("Please enter valid email address"))
      .required(t("Email is required.")),
    password: yup
      .string()
      .when("initialValues", {
        is: value,
        then: yup
          .string()
          .min(8, t("At least 8 characters."))
          .required(t("Password is required.")),
      })
      .min(8, t("At least 8 characters.")),
  });

  const formik = useFormik<ClubFormInterface>({
    initialValues: value || {
      club_name: "",
      incharge: "",
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (done) {
      formik.resetForm();
    }
  }, [done, formik]);

  return (
    <>
      <Container>
        <FormContainer>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="club_name"
              error={formik.touched.club_name && formik.errors.club_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.club_name}
              label={t("Club Name")}
              className="club-text-field"
            />
            <TextField
              name="incharge"
              error={formik.touched.incharge && formik.errors.incharge}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.incharge}
              label={t("In Charge")}
              className="club-text-field"
            />
            <TextField
              name="email"
              error={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              label={t("Email")}
              className="club-text-field"
            />
            <TextField
              name="password"
              type="password"
              error={
                value
                  ? formik.errors.password
                  : formik.touched.password && formik.errors.password
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              label={t("Password")}
              className="club-text-field"
            />
            <BottomWrapper>
              <Button htmlType="submit" type="primary" loading={loading}>
                {value ? `${t("Update")}` : `${t("Add")}`}
              </Button>
            </BottomWrapper>
          </form>
        </FormContainer>
      </Container>
    </>
  );
};
export { ClubForm };
