import * as Sentry from "@sentry/node";
import { AppProps } from "next/app";
import { analytics, InternetStatus, theme, alert } from "@project/shared";
import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { useRouter } from "next/router";
import { Layout } from "antd";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "../utils/AuthContext";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../utils/globalStyles.css";
import { SideNav } from "../components";
import jwt_decode from "jwt-decode";

const { Sider } = Layout;

const LayoutWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout {
    background: ${theme.gray3};
    height: auto;
  }
  & .ant-layout-content {
    background: ${theme.gray3};
    height: auto;
  }
  .loader {
    display: flex;
    justify-content: center;
    margin-top: 25vh;
  }
  & .ant-layout-sider {
    height: 100%;
    background-color: blue;
  }
`;
const ContentWrapper = styled.div`
  height: 100vh;
`;

export { Layout };

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `glive-frontend-admin-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null as firebase.User | null);
  const [isAdmin, setIsAdmin] = useState<any>(null);
  const [collapse, setCollapse] = useState(false);
  const { t } = useTranslation();
  const { Content } = Layout;

  const restrictedRoute = [
    "/login",
    "/forgot-password",
    "/forgot-password/done",
  ];

  useEffect(() => {
    setCollapse(collapse);
  }, [collapse]);
  const queryClient = new QueryClient();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url);
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "SPOMACHI",
        });
      };

      routers.events.on("routeChangeComplete", logEvent);
      logEvent(window.location.pathname);
      return () => {
        routers.events.off("routeChangeComplete", logEvent);
      };
    }
  }, []);

  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      setTokenToAPI(user);
      if (user !== null) {
        const idToken = await user!.getIdTokenResult();
        if (idToken.claims["role"] === "admin") {
          setUser(user);
        } else {
          firebase.auth().signOut();
          setUser(null);
          alert({ message: t("Unauthorized User") });
        }
      }
      setLoading(false);
    });
  };

  const setTokenToAPI = async (user: firebase.User) => {
    if (user !== null) {
      try {
        const token = await user.getIdTokenResult(true);
        setLoading(true);
        const decodedToken: {
          email: string;
          userID: string;
          role: string;
        } = jwt_decode(token?.token);

        setIsAdmin(decodedToken.role === "admin");

        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <InternetStatus
        noNotification
        window={global.window}
        message={t("No Internet")}
        description={t(
          "Internet connection couldn't be established, please check your connection."
        )}
      />
      <AuthProvider
        loading={loading}
        user={user}
        setUser={setUser}
        isAdmin={isAdmin}
      >
        <LayoutWrapper>
          {!restrictedRoute.includes(routers.pathname) && user ? (
            <Layout>
              <Sider>
                <SideNav />
              </Sider>
              <Content>
                <ContentWrapper>
                  <Component {...pageProps} />
                </ContentWrapper>
              </Content>
            </Layout>
          ) : (
            <Content>
              <ContentWrapper>
                <Component {...pageProps} />
              </ContentWrapper>
            </Content>
          )}
        </LayoutWrapper>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default MyApp;
