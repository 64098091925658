import { useContext } from "react";
import Router, { useRouter } from "next/router";
import { firebase, MenuGenerator, theme } from "@project/shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AuthContext } from "../../../utils/AuthContext";
import * as Sentry from "@sentry/node";

import Logo from "../../../assets/HeaderLogo.svg";
import ClubList from "../../../assets/ClubList.svg";
import Logout from "../../../assets/Logout.svg";
import { destroyCookie } from "nookies";

const Wrapper = styled.div`
  background-color: ${theme.white};
  font-family: "Noto Sans JP", sans-serif;
  position: sticky;
  top: 0px;

  .ant-menu {
    background: ${theme.white};
    font-size: 16;
    height: calc(100vh - 54px);
  }

  .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const SideNavHeadWrapper = styled.div`
  height: 64px;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  background-color: ${theme.white};
  border: none;
`;
const SideNavIcon = styled.span`
  margin-right: 8px;
`;

const SideNav = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const selectKey = router.pathname.split("/");
  if (selectKey[1] === "menu1-submenu1") selectKey[1] = "menu1";
  const selectKeyDefault = selectKey[1] || "menu1";

  const { setUser } = useContext(AuthContext);

  const handleSignOut = async () => {
    destroyCookie({}, "accessToken", {
      pathname: "/login",
    });
    await firebase.auth().signOut();
    setUser(null);
    Sentry.configureScope((scope) => scope.setUser(null));
    Router.push("/login");
  };

  const menus = [
    {
      className: null,
      key: "1",
      onClick: () => router.push("/club"),
      icon: (
        <SideNavIcon>
          <ClubList />
        </SideNavIcon>
      ),
      name: t("Club List"),
    },
    {
      className: null,
      key: "logout",
      onClick: handleSignOut,
      icon: (
        <SideNavIcon>
          <Logout />
        </SideNavIcon>
      ),
      name: t("Logout"),
    },
  ];

  return (
    <Wrapper>
      <SideNavHeadWrapper>
        <Logo />
      </SideNavHeadWrapper>
      <MenuGenerator
        mode="inline"
        defaultSelectedKeys={[selectKeyDefault]}
        items={menus}
      />
    </Wrapper>
  );
};

export { SideNav };
